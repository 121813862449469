import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootProvider } from 'providers/RootContext';
import {
  Grid,
  CircularProgress
} from '@mui/material';
import {
  InfoPanel,
  InfoPanelItem,
  SpacerV,
} from 'components/common';
import EditCompany from './EditCompany';
import _ from "lodash";
import {useParams} from "react-router-dom";
import {toJS} from "mobx";
import businessProvider from "../../../providers/BusinessProvider";


const General = observer(() =>  {
  const {id} = useParams<{id: string}>();
  const { businessProvider: bp, drawerProvider: dp } = useRootProvider();

  useEffect(() => {
    if (id) {
      bp.setAccount(undefined);

      bp.getAccount(id).then(() => {
        console.log(toJS(bp.business));
      });
    }
  }, [id, bp]);

  const companyInfoFields = ['name', 'ein', 'address', 'address 2', 'city', 'state', 'zip', 'email', 'phone'];

  const getCompanyInfo = () => {
    if (bp.business) {
      return _.pick(bp.business, companyInfoFields);
    }
    return {};
  }

  const getDrawerContent = () => <EditCompany businessInfo={getCompanyInfo()} onSave={bp.saveAccount} />;

  const editCompanyInfo = () => {
    dp.drawerOpen(getDrawerContent(), 'Edit Company');
  }

  return (
    <div>
      <SpacerV size="30" />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {(bp.isFetching || !bp.business) &&
            <CircularProgress />
          }

          {!bp.isFetching && bp.business &&
            <InfoPanel onEdit={editCompanyInfo} title="Company Info">

              {companyInfoFields.map((f) => (
                <InfoPanelItem key={f} label={f} value={(bp.business as any)[f]} />
              ))}

            </InfoPanel>
          }

        </Grid>
      </Grid>
    </div>
  );
});

export default General;

