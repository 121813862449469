import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {useRootProvider} from 'providers/RootContext';
import {Box, CircularProgress, MenuItem, Typography} from '@mui/material';
import {SpacerV, YButtonPrimary, YCenter, YDateRangePicker, YTagsField, YTextField} from 'components/common';
import {DashboardWizard} from "../../components/DashboardWizard";
import CreateBrand from "../business/CreateBrand";
import CreateLocation from "../business/locations/CreateLocation";
import {useForm} from "react-hook-form";
import CreateCampaign from "../campaigns/CreateCampaign";
import {CampaignEmptyForm} from "../../data/formValues";
import Api from "../../api/api";
import {toJS} from "mobx";
import {ConnectMenu} from "./ConnectMenu";

const Dashboard = observer(() => {
  const {businessProvider: bp, campaignProvider: cp, tutorialProvider: tp, drawerProvider: dp, userProvider: up} = useRootProvider();
  const navigate = useNavigate();
  const { setValue, ...form } = useForm({ defaultValues: {}, mode: 'onChange' });
  const [catalogs, setCatalogs] = useState<any[]>([]);

  const completedBrand = () => bp.brands !== undefined && bp.brands.length > 0;
  const completedLocation = () => bp.locations !== undefined && bp.locations.length > 0;

  const completedMenu = () => {
    if (bp.locations === undefined) {
      return false;
    } else {
      if (bp.locations.length === 0) {
        return false;
      }
    }
    return !(!catalogs || catalogs.length === 0);
  }
  const completedCampaign = () => cp.campaigns !== undefined && cp.campaigns.length > 0;

  const getStepIndex = () => {
    if (!completedBrand()) {
      return 0;
    }
    if (!completedLocation()) {
      return 1;
    }
    if (!completedMenu()) {
      return 2;
    }
    if (!completedCampaign()) {
      return 3;
    }
    return 4;
  }

  const shouldShowWizard = () => {
    return !up.isAdmin && getStepIndex() < 4;
  }

  const goToLocations = () => {
    navigate('/settings/locations');
  }

  const goToCampaigns = () => {
    navigate('/campaigns');
  }

  useEffect(() => {
    if (bp.locations === undefined) {
      bp.getLocations().then(() => {
        cp.getCampaigns().then(() => {
          tp.inspect();
        });
      });
    }
  }, [bp, cp, tp]);

  useEffect(() => {
    const fetchData = async () => {
      bp.locations?.forEach((loc: any) => {
        Api.catalog.getLocationCatalogs(loc.id).then((resp) => {
          setCatalogs([...catalogs, ...resp?.data]);
        });
      });
    };

    fetchData();
  }, [bp, bp.locations]);

  const onClickAddBrand = () => {
    dp.drawerOpen(<CreateBrand/>, 'Create Brand');
  }

  const getLocationDrawerContent = () => {
    return <CreateLocation form={form} setValue={setValue} onAction={bp.createLocation} initialAddress={undefined} />;
  };

  const onClickAddLocation = () => {
    dp.drawerOpen(getLocationDrawerContent(), 'Add Location');
  }

  const onClickConnectMenu = () => {
    dp.drawerOpen(
      <ConnectMenu />,
      'Connect Menu'
    );
  }

  const initialCampaignOpen = () => {
    cp.setDefaults('campaign', CampaignEmptyForm);
    cp.resetContext();
    cp.setCurrentStep(0);
  }

  const onClickCreateCampaign = () => {
    navigate('/campaigns');
    cp.startNewCampaign();
  }

  const callbacks = [
    onClickAddBrand,
    onClickAddLocation,
    onClickConnectMenu,
    onClickCreateCampaign
  ];

  return (
    <div>
      {(bp.isFetching || cp.fetching) &&
        <YCenter>
          <CircularProgress color="primary"/>
        </YCenter>}

      {(!bp.isFetching && !cp.fetching) && shouldShowWizard() &&
        <DashboardWizard callbacks={callbacks} activeStepIndex={getStepIndex()}/>}

      {(!bp.isFetching && !cp.fetching) && !shouldShowWizard() &&
        (<div>
          <h1>You're all set</h1>
          <Typography>You can add more campaigns from campaigns page</Typography>
          <SpacerV size="20"/>
        </div>)
      }
    </div>
  );
});

export default Dashboard;
