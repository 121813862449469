import { AxiosInstance } from 'axios';
import { Api, createApi } from './api';
import BusinessMock from 'data/business';

class Business {
  public path: String = 'business/v1';
  public client?: AxiosInstance | undefined;

  constructor() {
    const port = process.env['REACT_APP_BUSINESS_API_PORT'];
    this.client = createApi(this.path, port);
  }

  /* Returns list of accounts for admins */
  public listAccounts = async (): Promise<any> => {
    if (this.client === undefined) {
      return Promise.resolve(BusinessMock.listAccounts());
    }

    const path = '/accounts';
    return await Api.get(this.client!, path);
  };

  /* Retrieves list of users that work for accounts and have access to dashboard */
  public listMembers = async (): Promise<any> => {
    if (this.client === undefined) {
      return Promise.resolve(BusinessMock.listUsers());
    }

    const path = '/users';
    return await Api.get(this.client!, path);
  };

  public getAccount = async (id: string) : Promise<any> => {
    const path = `/account/${id}`;
    const res = await Api.get(this.client!, path);

    return Promise.resolve(res.data);
  };

  public accountExists = async (ein: string) : Promise<boolean> => {
    const path = `accounts/account-exists?ein=${ein}`;
    const res = await Api.get(this.client!,  path);

    return Promise.resolve(res.data.exists);
  }

  public accounts = async (userId: string) : Promise<any> => {
    const path = `/users/${userId}/accounts`;

    return await Api.get(this.client!, path);
  }

  public account = async (accountId: string) : Promise<any> => {
    const path = `/accounts/${accountId}`;

    return await Api.get(this.client!, path);
  }

  public addAccount = async (userId: string, account: any) : Promise<any> => {
    const path = `/users/${userId}/accounts`;

    return await Api.post(this.client!, path, account);
  }

  public brandsForAccount = async (accountId: string) : Promise<any> => {
    const path = `/accounts/${accountId}/brands`;
    return await Api.get(this.client!, path);
  }

  public brands = async (ids: string = '') : Promise<any> => {
    const path = ids === '' ? `/brands` :`/brands?ids=${ids}`;
    return await Api.get(this.client!, path);
  }

  public createBrand = async (accountId: string, brand: any) => {
    const path = `/accounts/${accountId}/brands`;
    return await Api.post(this.client!, path, brand);
  }

  public deleteBrand = async (brandId: string) => {
    const path = `/brands/${brandId}`;
    return await Api.delete(this.client!, path);
  }

  public teamForAccount = async (accountId: string) : Promise<any> => {
    const path = `/accounts/${accountId}/users`;
    return await Api.get(this.client!, path);
  }

  public locationsForAccount = async (accountId: string, brandId: string = '') : Promise<any> => {
    let path = `/accounts/${accountId}/locations`;
    if (brandId) {
      path = `${path}?brandId=${brandId}`;
    }

    return await Api.get(this.client!, path);
  }

  public saveAccount = async (accountId: string, data: any) : Promise<any> => {
    const path = `/accounts/${accountId}`;
    return await Api.patch(this.client!, path, data);
  }

  public createLocation = async (accountId: string, location: any): Promise<any> => {
    const path = `/accounts/${accountId}/locations`;
    return await Api.post(this.client!, path, location);
  }

  public deleteLocation = async (locationId: string): Promise<any> => {
    const path = `/locations/${locationId}`;
    return await Api.delete(this.client!, path);
  }

  public updateLocation = async (accountId: string, locationId: string, location: any): Promise<any> => {
    const path = `/accounts/${accountId}/locations/${locationId}`;
    return await Api.patch(this.client!, path, location);
  }

  public updateAccountLocation = async (locationId: string, location: any): Promise<any> => {
    const path = `/locations/${locationId}`;
    return await Api.patch(this.client!, path, location);
  }

  public saveLocationSettings = async (locationId: string, settings: any): Promise<any> => {
    const path = `/locations/${locationId}/settings`;
    return await Api.post(this.client!, path, settings);
  }

  public listLocations = async (): Promise<any> => {
    const path = `/locations`;
    return await Api.get(this.client!, path);
  }
}

export default Business;
