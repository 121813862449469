import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import YGrid from 'components/common/YGrid';
import { useRootProvider } from 'providers/RootContext';
import {
  Box, Button, Fab
} from '@mui/material';
import {YCard} from "../../components/common";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {Add} from "@mui/icons-material";
import {CreateUserAccount} from "./CreateUserAccount";

const Accounts = observer(() => {
  const columns = [
    { field: 'name', headerName: 'Name', flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          color="primary"
          sx={{textTransform: 'none'}}
          href={`/accounts/${params.row.id}/general`}
        >{params.row.name}
        </Button>
    )},
    { field: 'ein', headerName: 'EIN' },
    { field: 'address', headerName: 'Address', flex: 0.4 },
    { field: 'city', headerName: 'City', flex: 0.2 },
    { field: 'state', headerName: 'State' },
    { field: 'phone', headerName: 'Phone', minWidth: 150 },
  ];

  const { businessProvider: bp, drawerProvider: dp } = useRootProvider();

  useEffect(() => {
    bp.getAccounts();
  }, [bp]);

  const handleCreateAccount = () => {
    dp.drawerOpen(<CreateUserAccount />, 'Create Account');
  };

  return (
    <Box sx={{width: '100%', height: '100%'}}>
      <YCard>
        <YGrid
          columns={columns}
          rows={bp.accounts}
        />
      </YCard>

      {!bp.isFetching &&
        <Fab color="primary"
             sx={{position: 'fixed', bottom: 20, right: 20}}
             onClick={handleCreateAccount}>
          <Add/>
        </Fab>
      }
    </Box>
  );
})

export default Accounts;
