import React from 'react';
import {Stack,} from '@mui/material';
import {DataGridPro} from "@mui/x-data-grid-pro";

interface Props {
  rows: any[];
  columns: any[];
  page?: number;
}

export const YGrid: React.FC<Props> = props => {

  let paginationModel: any = undefined;
  if (props.page !== undefined) {
    paginationModel = {
      pageSize: 100,
      page: props.page,
    }
  }

  return (
    <DataGridPro
      sx={{ backgroundColor: '#FFFFFF', border: 0 }}
      columns={props.columns}
      rows={props.rows}
      autoHeight={true}
      disableRowSelectionOnClick
      rowSpacingType="margin"
      components={{
        NoRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No data available
          </Stack>
        ),
        NoResultsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            Filters do not match any criteria
          </Stack>
        )
      }}
      pagination={props.page !== undefined}
      paginationModel={paginationModel}
    />
  );
}

export default (YGrid);

