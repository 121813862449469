import {observer} from "mobx-react";
import {useRootProvider} from "../../providers/RootContext";
import {useForm} from "react-hook-form";
import {Box} from "@mui/material";
import {YAutocomplete, YButtonPrimary, YTextField} from "../../components/common";
import React, {useEffect, useState} from "react";
import YGoogleAddressAutocomplete, {AddressData} from "../../components/common/YGoogleAddressAutocomplete";
import {toJS} from "mobx";
import AccountEntry from "../../types/AccountEntry";

export const CreateUserAccount = observer(() => {
  const { businessProvider: bp, drawerProvider: dp, usersProvider: up } = useRootProvider();
  const form = useForm({mode: 'onChange'});
  const [userOptions, setUserOptions] = useState<any[]>([]);

  useEffect(() => {
    up.getUsers().then(() => {
      const options = up.users.map((user: any) => ({
        name: `${user.name || ''} ${user.familyName || ''} (${user.email})`,
        item: user
      }));
      setUserOptions(options);
    });
  }, [up]);

  const onSubmit = async (data: any) => {
    try {
      const formattedData: AccountEntry = {
        name: data.name,
        ein: data.ein,
        address: data.address,
        city: data.city,
        zip: data.zip,
        state: data.state,
        country: data.country,
        lat: data.lat,
        lng: data.lng
      };

      console.log(toJS(formattedData));
      await bp.createAccountForUser(data.user.item.id, formattedData).then((data) => {
        if (data) {
          bp.getAccounts();
          dp.close();
        }
      });
    } catch (e) {
      // Error handling
    }
  };

  const onPlaceSelect = (data: AddressData) => {
    if(data.street || data.streetNumber){
      form.setValue("address", `${data.streetNumber} ${data.street}`)
    }
    if(data.city){
      form.setValue("city", data.city);
    }
    if(data.zip){
      form.setValue("zip", data.zip);
    }
    if(data.state) {
      form.setValue('state', data.state);
    }
    if(data.country) {
      form.setValue('country', data.country);
    }
    if(data.lat) {
      form.setValue('lat', data.lat);
    }
    if(data.lng) {
      form.setValue('lng', data.lng);
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <div>
        <YAutocomplete
          control={form.control}
          name="user"
          options={userOptions}
          label="Select User"
          isRequired
        />

        <YTextField
          fullWidth
          label="Company Name"
          control={form.control}
          isRequired
          name="name"
        />

        <YTextField
          fullWidth
          label="EIN Number"
          control={form.control}
          minLength={9}
          maxLength={9}
          isRequired
          name="ein"
        />

        <YGoogleAddressAutocomplete
          name="place"
          label="Company address"
          control={form.control}
          onPlaceSelect={onPlaceSelect}
          isRequired
        />
      </div>

      <Box>
        <YButtonPrimary
          fullWidth
          onPress={form.handleSubmit(onSubmit)}
          disabled={!form.formState.isValid}
        >
          Create
        </YButtonPrimary>
      </Box>
    </Box>
  );
});